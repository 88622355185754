<template>
  <section class="service-section">
    <h2 class="service-title">Nos services d'hivers</h2>
    <div>
    <div class="service-container">

        <div class="service-box image-box img-1">
          <h3>Stationnement</h3>
          <p>
          Pour le déneigement de stationnement, GMR Déneigement assure un service rapide et efficace, même dans les conditions hivernales les plus difficiles. Nous utilisons des équipements modernes pour dégager votre stationnement en un rien de temps, garantissant un accès sécuritaire et sans tracas pour vos véhicules. Que ce soit pour des petits espaces ou de grands stationnements, notre équipe est prête à intervenir, dès les premières heures du matin, pour que vous puissiez partir sans souci.
        </p>
      </div>
      <div class="service-box image-box img-2">
        <h3>Allées</h3>
        <p>
          Pour le déneigement des trottoirs et des sentiers, GMR Déneigement s'occupe de tout. Nous veillons à ce que vos passages piétons soient dégagés rapidement et efficacement, permettant une circulation sans danger, même après une grosse tempête. Nos équipes s'assurent que vos allées restent praticables et sécurisées tout au long de l'hiver.
        </p>
      </div>
      <div class="service-box image-box img-4">
        <h3>Toiture</h3>
        <p>GMR Déneigement utilise des équipements de pointe, dont l'aérien Mamouth #1 sur le marché, pour offrir un déneigement de toiture rapide et sécuritaire. Grâce à cette technologie, nous garantissons une efficacité inégalée tout en protégeant l'intégrité de votre bâtiment contre les risques liés à l'accumulation de neige.</p>
      </div>  
      <div class="service-box image-box img-3">
        <h3>Épandage d'abrasif</h3>
        <p>L'épandage d'abrasif est un service hivernal clé pour sécuriser vos surfaces extérieures. En appliquant un mélange de sel et de gravier sur les zones glacées ou enneigées, nous réduisons les risques de glissades et améliorons l'adhérence pour les piétons et les véhicules. Notre service est rapide, efficace, et adapté aux conditions pour assurer votre sécurité tout l'hiver.</p>
      </div>
      </div>
    </div>
  </section>
</template>



<style scoped>

.img-1 {
  background-image: url('../img/img1.jpg');
}

.img-2 {
  background-image: url('../img/img2.jpg');
}

.img-3 {
  background-image: url('../img/img3.jpg');
}

.img-4 {
  background-image: url('../img/img4.jpg');
}

.service-section {
  padding: 2rem;
  background-color: #f0f0f0;
}
.service-title {
  color: black;
  text-align: center;
  font-size: 2rem;
  margin-bottom: 2rem;
}

.service-container {
  display: flex;
  flex-wrap: wrap;
  width: fit-content;
  gap: 2rem;
  justify-content: center;
}

.service-box {
  background-color: white;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  flex: 1 1 300px;
  max-width: 400px;
}

.service-box h3 {
  color: #333;
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.service-box p {
  font-size: 1rem;
  line-height: 1.6;
  color: #333;
}

.image-box {
  background-size: cover;
  background-position: center;
  position: relative;
}

.image-box::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(0.5px);
}

.image-box h3,
.image-box p {
  position: relative;
  z-index: 1;
}

 
</style>