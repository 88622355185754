<template>
    <section id="contact">
        <h2>Demandez un devis</h2>

        <h3>Contactez-nous directement par téléphone ou remplissez le formulaire ci-dessous</h3>
        <br>

        <div id="grp-ct">
            <!-- <div>
<h3>                Vous cherchez un déneigeur fiable et efficace pour garder votre résidence dégagée tout l’hiver ?
</h3>
            </div> -->
            <div id="ct-box">
                <h3 class="h3-ct">CONTACTEZ-NOUS</h3>
                <form @submit.prevent="submitForm">
                    <div>

                                        <div class="form-group">
                        <label for="nom">Nom:</label>
                        <input type="text" id="nom" v-model="contactForm.nom" name="nom" required />
                    </div>
                    
                    <div class="form-group">
                        <label for="email">Email:</label>
                        <input type="email" id="email" v-model="contactForm.email" name="email" required />
                    </div>
                    
                    <div class="form-group">
                        <label for="telephone">Téléphone:</label>
                        <input type="tel" id="telephone" v-model="contactForm.telephone" name="telephone" required />
                    </div>
                    <div class="form-group">
                        <label for="adresse">Adresse:</label>
                        <input type="text" id="adresse" v-model="contactForm.adresse" name="adresse" required />
                    </div>
                </div>

                    <div>

                    <div id="box-select">
                        <div class="form-group ">
                            <label for="typePropriete">Propriété:</label>
                            <select id="typePropriete" v-model="contactForm.typePropriete" name="typePropriete" required>
                                <option value="maison">Résidentiel</option>
                                <option value="condo">Comercial</option>
                                <option value="immeuble">Multi-Logements</option>
                                <option value="autre">Autre</option>
                            </select>
                        </div>
                        <div class="form-group">
                            <label for="service">Service:</label>
                            <select id="service" v-model="contactForm.service" name="service" required>
                                <option value="stationnement">Stationnement</option>
                                <option value="trottoirs">Trottoirs</option>
                                <option value="toiture">Toiture</option>
                                <option value="autre">Autre</option>
                            </select>
                        </div>
                 
                    </div>
                    <div class="form-group">
                        <label for="message">Message:</label>
                        <textarea id="message" v-model="contactForm.message" name="message" cols="30" rows="5"
                        required></textarea>
                    </div>

                    
                    
                    <button class="btn-envoie" type="button" @click="submitForm">Envoyer</button>
                </div>
                
                </form>
                
            </div>
        </div>
            
        </section>
</template>


<style scoped>


#info-contact > div:nth-child(3) {
  width: 100%;
  height: 2px;
  background: white;
}

.form-group > select{
    width: calc(100% - 20px);
  padding: 10px;
  border: 1px solid #ddd;
  background-color: #ffffff;
  border-radius: 5px;
  font-size: 1rem;
}

#grp-ct > div:nth-child(1) > h3:nth-child(1) {
  font-size: 22px;
  max-width: 600px;
}

#grp-ct > div:nth-child(1) > iframe:nth-child(2) {
  border: 0px;
  max-width: 600px;
  width: 550px;
  padding: 20px;
}

#grp-ct {
  display: flex;
 justify-content: space-around;
  align-items: center;
  margin: 50px auto;
  flex-wrap: wrap;
}

#box-select {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}


#contact {
    position: relative;
    color: #ffffff;
    padding: 30px 20px;
    text-align: center;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.097);
}

#contact::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../img/bgTeste.jpg');
    background-size: cover;
    background-position: center;
    filter: blur(0px) brightness(0.8) contrast(1.2);
    border-radius: 8px; /* Optionnel : pour arrondir les coins du fond */
    z-index: -1; /* Assure que l'image de fond soit derrière le contenu */
}


h2 {
    color: #ffffff;
    margin-bottom: 20px;
    font-size: 2rem;
    text-align: center;
}
h3{
    color: #ffffff;
}
p {
    color: #ffffff;
    margin-bottom: 20px;
    font-size: 1.1rem;
}

#ct-box {
    background-color: #ffffff;
    color: #333333;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: left;
    max-width: 600px;

}

.h3-ct {
    color: black;
    margin-bottom: 20px;
    font-size: 1.5rem;
    text-align: center;
}

.form-group {
    margin-bottom: 15px;
}

.form-group label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
}

.form-group input,
.form-group textarea {
    width: calc(100% - 20px);
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 1rem;
}

.form-group input:focus,
.form-group textarea:focus {
    border-color: black;
    outline: none;
    box-shadow: 0 0 5px black;
}

textarea {
    resize: vertical;
}

.btn-envoie {
    display: block;
    background-color: black;
    color: #ffffff;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 1rem;
    cursor: pointer;
    margin: 20px auto;
    transition: background-color 0.3s ease;
}

.btn-envoie:hover {
    background-color: #015f7d;
}

.btn-envoie:focus {
    outline: none;
    box-shadow: 0 0 5px black;
}

@media screen and (max-width: 768px) {


#ct-box > form:nth-child(2) {
  display: flex;
  flex-direction: column;
}


}

#ct-box > form:nth-child(2) {
  display: flex;
}

#ct-box > form:nth-child(2) {
  display: flex;
}


.btn-envoie {
  height: 40px;
}


#ct-box > form:nth-child(2) {
  display: flex;
}



#message {
  height: 140px;
}

</style>

<script setup>
import { ref } from 'vue';
import emailjs from 'emailjs-com';

const contactForm = ref({
    nom: "",
    email: "",
    telephone: "",
    adresse: "",
    service: "",
    message: "",
    typePropriete: "",
});

const submitForm = () => {
    // Traitement des données du formulaire (e.g., envoi à un serveur)
    console.log('Form data:', contactForm.value);

    // Configurer les paramètres de l'email
    const templateParams = {
        nom: contactForm.value.nom,
        email: contactForm.value.email,
        telephone: contactForm.value.telephone,
        adresse: contactForm.value.adresse,
        service: contactForm.value.service,
        message: contactForm.value.message,
        typePropriete: contactForm.value.typePropriete,
    };

    // Envoyer l'email via EmailJS
    emailjs.send('service_zduw6kf', 'template_74ynwi7', templateParams, 'Ludk6CzxhYv2Elv2G')
    .then((response) => {
        console.log('Email envoyé avec succès!', response.status, response.text);
        alert('Votre demande de devis a été envoyée avec succès.');
    }, (error) => {
        console.error('Erreur lors de l\'envoi de l\'email:', error);
        alert('Une erreur s\'est produite lors de l\'envoi du devis. Veuillez réessayer.');
    });

    // Réinitialisation du formulaire après soumission
    contactForm.value = {
        nom: "",
        email: "",
        telephone: "",
        adresse: "",
        service: "",
        message: "",
        typePropriete: "",
    };
};
</script>
